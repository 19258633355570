import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import BoldTitle from '../components/BoldTitle';
import { CommonContext } from '../context';
import { Link } from 'gatsby';

function OurServicesPage({ location }) {
  const renderTopSection = () => (
    <div className="flex justify-center w-full bg-primary-0">
      <div className="w-full max-w-6xl p-4 md:grid md:grid-cols-2 md:pt-16 md:pb-20">
        <div className="p-6 md:order-2">
          <img
            src="/images/greentech-apps-services.svg"
            alt="Greentech Apps Limited Services"
          />
        </div>
        <div className="p-6 md:order-1 md:pt-20">
          <BoldTitle className="mb-6">
            Expert Services, <span className="text-primary">Limitless</span>{' '}
            Potential
          </BoldTitle>
          <div className="leading-loose font-regular md:pr-20">
            Unleash your project&apos;s <b>potential</b> with our <b>expert</b>{' '}
            services.
            <b>Cutting-edge</b> technology and a skilled team delivering
            top-quality design and development solutions.
          </div>
        </div>
      </div>
    </div>
  );

  const services = [
    {
      title: 'UI-UX Design',
      icon: '/icons/diamond.svg',
      description:
        'A good design is essential for building an engaging and scalable product. We apply a multidisciplinary approach to craft a delightful User Experience for your digital product.',
    },
    {
      title: 'Mobile App Development',
      icon: '/icons/mobile.svg',
      description:
        'We help to get your business level up by building mobile applications which are both performant and user-friendly. We build both native and cross-platform applications covering both Android and iOS devices.',
    },
    {
      title: 'Web App Development',
      icon: '/icons/web.svg',
      description:
        'We build fast, efficient and user-friendly web applications using the latest technologies.',
    },
  ];

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout
        keywords={[`greentech`, `islam`, `projects`, `outsource`]}
        title="Our Services"
        description={
          "Unleash your project's potential with our expert services. Cutting-edge technology and a skilled team delivering top-quality design and development solutions."
        }
        location={location}
      >
        {renderTopSection()}
        <section className="px-6 py-16 full-width md:p-28">
          <div className="flex justify-center">
            <BoldTitle className="mb-10 text-center align-center">
              What We <span className="text-primary">Deliver</span>
            </BoldTitle>
          </div>
          <div className="grid max-w-5xl grid-cols-1 gap-6 mx-auto md:grid-cols-3 md:gap-10">
            {services.map((item, idx) => (
              <div
                className="flex flex-col items-center p-12 space-y-8 text-center rounded-lg shadow-xl"
                key={`service-item-${idx}`}
              >
                <img
                  src={item.icon}
                  alt={`Greentech Apps Limited ${item.title}`}
                  className="w-12 h-12"
                />
                <p className="text-3xl">{item.title}</p>
                <p className="text-sm">{item.description}</p>
              </div>
            ))}
          </div>
        </section>
        <section>
          <div className="flex justify-center w-full bg-primary-0">
            <div className="w-full max-w-6xl md:grid md:grid-cols-2 md:pt-16 md:pb-20">
              <div className="p-6">
                <img
                  src="/images/greentech-apps-project-quote.svg"
                  alt="Greentech Apps Limited Services"
                />
              </div>
              <div className="p-6">
                <BoldTitle className="mb-6">
                  Have a Project <br />
                  in <span className="text-primary">Mind</span>?
                </BoldTitle>
                <div>
                  <p className="text-sm leading-loose font-regular">
                    We are <b>constantly</b> updating and expanding our services
                    to <b>ensure</b> that we can provide the{' '}
                    <b>best possible solutions</b> for you.
                    <br />
                    If you don&apos;t see a service that fits your needs, please
                    don&apos;t hesitate to <b>contact us</b>. We would be more
                    than happy to <b>discuss custom solutions</b> and see how we
                    can help.
                  </p>
                  <Link
                    to="https://greentechapps.com/contact?utm_source=gtaf_website&utm_medium=cta"
                    target="_blank"
                  >
                    <div className="px-6 py-3 mt-6 font-bold text-center rounded-full bg-primary md:text-left md:max-w-fit text-neutral-0 hover:shadow-lg">
                      I want to Discuss
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="max-w-6xl p-6 pb-8 mx-auto md:p-20 md:pb-12">
            <div className="px-4 py-4 mx-auto rounded-md md:px-6 bg-primary-2">
              <p className="pl-4 leading-relaxed border-l-2 md:border-l-4 md:pl-6 border-primary-4">
                The <b>income</b> from these projects goes towards{' '}
                <b>Greentech Apps Foundation projects</b>. In other words, these
                projects help us <b>fuel and stabilize</b> Greentech Apps
                Foundation projects. Even if there is no project, you can always
                stay connected with us, share our work and consider us for{' '}
                <b>donations</b> if possible. <b>Thank you</b> for considering
                us for your service needs. We look forward to working with you!
              </p>
            </div>
          </div>
          <img
            src="/images/gtal-logo-horizontal.svg"
            className="mx-auto mb-20 w-60"
          />
        </section>
      </Layout>
    </CommonContext.Provider>
  );
}

OurServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default OurServicesPage;
